import React, { useEffect, useState } from "react";
import CategoryHeader from "./CategoryHeader";
import Item from "./Item";
import client, { urlFor } from "../sanity";
import lines from "../utils/lines2.png";
import clock from "../utils/time.png";
import clock2 from "../utils/time2.png";
import clock3 from "../utils/time3.png";
function Dondurma() {
  const [dondurma, setDondurma] = useState([]);
  useEffect(() => {
    client
      .fetch(
        `*[_type == "meal" && category->name == "Dondurma"] | order(priority asc){
      ...,
     category ->{
       ...
     }
    }`
      )
      .then((data) => {
        setDondurma(data);
      });
  }, []);
  return (
    <>
      <div
        className="flex flex-wrap m-auto w-full justify-center space-y-2"
        name="Dondurma"
      >
        {/* <div
					className={`bg-[#75A493] flex flex-col w-full self-center items-center content-center rounded-lg h-[200px]`}
				>
					<h1
						className={` text-[#fee7d7] text-5xl mb-0 font-extralight m-auto pb-3`}
					>
						Dondurma
					</h1>
					<img
						src={lines}
						className="w-full relative content-end bottom-0 h-[25px] select-none rounded-b-lg"
					/>
				</div>
				{dondurma?.map((item) => {
					return (
						<Item
							name={item.name}
							price={item.price}
							image={item.image}
							description={item.description}
						/>
					);
				})} */}
        <div className="flex h-[120px] bg-[#75A493] w-full rounded-lg justify-center items-end relative">
          <div className="flex flex-col justify-center items-center">
            <h2 className="text-4xl mb-2 text-[#fee7d7] font-light">Dondurma</h2>
          </div>
        </div>
        <div className="w-full grid grid-cols-2 gap-2">
          {dondurma?.map((item) => {
            return (
              <Item
                key={item._id}
                name={item.name}
                price={item.price}
                image={item.image}
                description={item.description}
              />
            );
          })}
        </div>
      </div>
    </>
  );
}

export default Dondurma;
