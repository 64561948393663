import React from 'react';
import { AiOutlineInstagram, AiOutlineFacebook, AiOutlineWhatsApp } from 'react-icons/ai';
export const Footer = () => {
  return (
    <div className='flex bg-[#71380F] text-white text-sm font-light mt-[30px] bottom-0  justify-center items-center p-[30px]'>
      <p>Copyright 2023 © <span className='font-normal'>Waffly</span><br />

        <span className='text-white flex flex-row justify-center items-center sticky w-full mt-1 text-xl'>
          <a className='p-[15px]' href='https://www.facebook.com/waffly.tr/?modal=admin_todo_tour' rel='noreferrer' target='_blank'><AiOutlineFacebook /></a>
          <a className='p-[15px]' href='https://www.instagram.com/waffly.tr/' rel='noreferrer' target='_blank'><AiOutlineInstagram /></a>
          <a className='p-[15px]' href='https://api.whatsapp.com/send?phone=905550113030' rel='noreferrer' target='_blank'><AiOutlineWhatsApp /></a>
        </span>

      </p>
    </div>
  );
}
