import React, { useEffect, useState } from "react";
import CategoryHeader from "./CategoryHeader";
import Item from "./Item";
import client, { urlFor } from "../sanity";
import lines from "../utils/lines2.png";

function Bento() {
  const [bento, setBento] = useState([]);
  useEffect(() => {
    client
      .fetch(
        `*[_type == "meal" && category->name == "Bento"] | order(priority asc){
      ...,
     category ->{
       ...
     }
    }`
      )
      .then((data) => {
        setBento(data);
      });
  }, []);
  return (
    <>
      {/* <div className="flex flex-wrap m-auto w-full justify-center space-y-2" name="Bento">
          <div className={`bg-[#fed9b3] flex flex-col w-full self-center items-center content-center rounded-lg h-[200px]`}>
              <h1 className={` text-black text-5xl mb-0 font-extralight m-auto pb-3`}>Bento</h1>
              <img src={lines} className="w-full relative content-end bottom-0 h-[25px] select-none rounded-b-lg" />
          </div>
          {bento?.map(item =>{
            return <Item name={item.name} price={item.price} 
                  image={item.image} 
                  description={item.description}/>
          })}
      </div> */}
      <div className="flex h-[120px] bg-[#fed9b3] w-full rounded-lg justify-center items-end relative" name="Bento">
        <div className="flex flex-col justify-center items-center">
          <h2 className="text-4xl mb-2 text-black font-light">
            Bento
          </h2>
        </div>
      </div>
      <div className="w-full grid grid-cols-2 gap-2">
        {bento?.map((item) => {
          return (
            <Item
              key={item._id}
              name={item.name}
              price={item.price}
              image={item.image}
              description={item.description}
            />
          );
        })}
      </div>
    </>
  );
}

export default Bento;
