import React from "react";
import Waffles from "./Waffles";
import Crepes from "./Crepes";
import Pancakes from "./Pancakes";
import Sweets from "./Sweets";
import Ekstra from "./Ekstra";
import Colds from "./Colds";
import Hots from "./Hots";
import Bento from "./Bento";
import Dondurma from "./Dondurma";
function Items() {
  return (
    <div className="space-y-5 px-5">
      <Waffles />
      <Pancakes />
      <Crepes />
      <Sweets />
      <Ekstra />
      <Colds />
      <Hots />
      <Bento />
      <Dondurma />
    </div>
  );
}

export default Items;
