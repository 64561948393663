import React, { useEffect, useState } from "react";
import Item from "./Item";
import client, { urlFor } from "../sanity";
import lines from "../utils/lines2.png";
import clock2 from "../utils/time2.png";
import clock3 from "../utils/time3.png";
import Warning from "../components/Warning";
function Sweets() {
  const [sweets, setSweets] = useState([]);
  useEffect(() => {
    client
      .fetch(
        `*[_type == "meal" && category->name == "Çikolatalı tatlılar"]| order(priority asc) {
      ...,
     category ->{
       ...
     }
    }`
      )
      .then((data) => {
        setSweets(data);
      });
  }, []);
  return (
    <>
      <div
        className="flex flex-wrap m-auto w-full justify-center space-y-2"
        name="Çikolatalı tatlılar"
      >
        {/* <div
					className={`bg-[#713810] flex flex-col w-full self-center items-center content-center rounded-lg h-[200px]`}
				>
					<div className="flex self-start content-start justify-start h-full p-2 m-[5px]">
						<img
							loading="lazy"
							className="w-[50px] h-[50px]"
							alt={"20dk"}
							src={clock3}
						></img>
						<p
							className={`text-left justify-start w-full self-start h-full text-white `}
						>
							{" "}
							HAZIRLAMA SÜRESİ
							<br />
							<span className="font-extralight">20 DAKİKADIR</span>
						</p>
					</div>
					<h1 className={` text-white text-4xl font-extralight m-auto pb-3`}>
						Çikolatalı tatlılar
					</h1>
					<img
						src={lines}
						className="w-full relative content-end bottom-0 h-[25px] select-none rounded-b-lg"
					/>
				</div>
				<Warning /> */}
        <div className="flex h-[120px] bg-[#713810] w-full rounded-lg justify-center items-end relative">
          <div className="flex absolute top-0 p-2 left-0">
            <img
              loading="lazy"
              className="w-[50px] h-[50px]"
              alt={"20dk"}
              src={clock3}
            ></img>
            <p
              className={`text-left justify-start w-full self-start h-full text-white `}
            >
              {" "}
              HAZIRLAMA SÜRESİ
              <br />
              <span className="font-extralight">20 DAKİKADIR</span>
            </p>
          </div>
          <h2 className="text-4xl mb-2 text-white font-light">Çikolatalı tatlılar</h2>
        </div>
        <div className="w-full grid grid-cols-2 gap-2">
          {sweets?.map((item) => {
            return (
              <Item
                key={item._id}
                name={item.name}
                price={item.price}
                onSale={item.onSale}
                image={item.image}
                description={item.description}
              />
            );
          })}
        </div>
      </div>
    </>
  );
}

export default Sweets;
