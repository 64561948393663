import React from "react";
import logo from "../utils/logo.png";
import Categories from "./Categories";
function Header() {
	return (
		<div className="bg-[#F0F0F0] sticky flex justify-center items-center top-0 p-3 z-50">
			<img
				onClick={() => window.scrollTo({ top: 0, left: 0, behavior: "smooth" })}
				src={logo}
				className="cursor-pointer h-10 p-15 justify-center"
			/>
			{/* <Categories /> */}
		</div>
	);
}

export default Header;
