import React, { useEffect, useState } from "react";
import CategoryHeader from "./CategoryHeader";
import Item from "./Item";
import client, { urlFor } from "../sanity";
import lines from "../utils/lines2.png";
import clock from "../utils/time.png";
import clock2 from "../utils/time2.png";
import clock3 from "../utils/time3.png";
import { IoIosWarning } from "react-icons/io";
import Warning from "./Warning";
function Waffles() {
  const [waffles, setWaffles] = useState([]);
  useEffect(() => {
    client
      .fetch(
        `*[_type == "meal" && category->name == "Waffles"] | order(priority asc){
      ...,
     category ->{
       ...
     }
    }`
      )
      .then((data) => {
        setWaffles(data);
      });
  }, []);
  return (
    <>
      <div
        className="flex flex-wrap m-auto w-full justify-center space-y-2"
        name="Waffles"
      >
        {/* <div
					className={`bg-[#37a9ad] flex flex-col w-full self-center items-center content-center rounded-lg h-[100px]`}
				>
					<div className="flex self-start content-start justify-start h-full p-2 m-[5px]">
						<img
							loading="lazy"
							className="w-[50px] h-[50px]"
							alt={"20dk"}
							src={clock}
						></img>
						<p
							className={`text-left justify-start w-full self-start h-full text-white `}
						>
							{" "}
							HAZIRLAMA SÜRESİ
							<br />
							<span className="font-extralight">20 DAKİKADIR</span>
						</p>
					</div>
					<h1 className={` text-white text-5xl justify-self-end mb-20`}>
						Waffles
					</h1>
					<img
						src={lines}
						className="w-full relative content-end bottom-0 h-[25px] select-none rounded-b-lg"
					/>
				</div> */}
        <div className="flex h-[120px] bg-[#37a9ad] w-full rounded-lg justify-center items-end relative">
          <div className="flex absolute top-0 p-2 left-0">
            <img
              loading="lazy"
              className="w-[50px] h-[50px]"
              alt={"20dk"}
              src={clock}
            ></img>
            <p
              className={`text-left justify-start w-full self-start h-full text-white `}
            >
              {" "}
              HAZIRLAMA SÜRESİ
              <br />
              <span className="font-extralight">20 DAKİKADIR</span>
            </p>
          </div>
          <h2 className="text-4xl mb-2 text-white font-light">Waffles</h2>
        </div>
        {/* <Warning /> */}
        <div className="w-full grid grid-cols-2 gap-2">
          {waffles?.map((item) => {
            return (
              <Item
                key={item._id}
                name={item.name}
                price={item.price}
                image={item.image}
                description={item.description}
              />
            );
          })}
        </div>
      </div>
    </>
  );
}

export default Waffles;
