import React from "react";
import { urlFor } from "../sanity";
import { Link } from "react-scroll";
function CategoryCard({ img, title }) {
  const getColor = (catName) => {
    switch (catName) {
      case "Waffles":
        return "#37a9ad"
      case "Pancakes":
        return "#FED9B3";
      case "Crepes":
        return "#A3DADF";
      case "Çikolatalı tatlılar":
        return "#713810";
      case "Ekstra":
        return "#37a9ad";
      case "Soğuk İçecekler":
        return "#FEE7D7";
      case "Sıcak İçecekler":
        return "#A4C8C2";
      case "Bento":
        return "#fed9b3";
      case "Dondurma":
        return "#75A493";
      default:
        return "#ffff"
    }
  }
  const getTextColor = (catName) => {
    switch (catName) {
      case "Waffles":
        return "#fff"
      case "Pancakes":
        return "#713810";
      case "Crepes":
        return "#713810";
      case "Çikolatalı tatlılar":
        return "#fff";
      case "Ekstra":
        return "#fff";
      case "Soğuk İçecekler":
        return "#000";
      case "Sıcak İçecekler":
        return "#fff";
      case "Bento":
        return "#000";
      case "Dondurma":
        return "#fee7d7";
      default:
        return "#ffff"
    }
  }
  return (
    <Link to={title} offset={-160}>
      <div className="flex w-full h-[90px] items-center justify-center">
        <div
          className={`flex items-center rounded-lg cursor-pointer 
					bg-[${getColor(title)}]
					 w-[150px] h-[50px] m-2.5`}
        >
          {/* <img
						src={urlFor(img).url()}
						className="w-[70px] rounded-lg justify-start"
					/> */}
          <div className={`content-center text-center w-full`} style={{ color: getTextColor(title) }}>{title}</div>
        </div>
      </div>
    </Link>
  );
}

export default CategoryCard;
