import React, { useEffect, useState } from 'react';
import { BsFillArrowUpCircleFill } from 'react-icons/bs'
export const ScrollToTop = () => {
  const [isVisible, setIsVisible] = useState(false);
  const toggleVisibility = () => {
    if (window.scrollY > 200) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  useEffect(() => {
    window.addEventListener('scroll', toggleVisibility);
    return () => {
      window.removeEventListener('scroll', toggleVisibility)
    };
  }, []);

  return (
    <div>

      <BsFillArrowUpCircleFill className={isVisible ? 'text-[50px] fixed drop-shadow-md ease-out-expo text-[#48D1CC] cursor-pointer opacity-70 m-[15px] bottom-0 right-0 rounded-full transition duration-200'
        : 'opacity-0 transition-opacity ease-in-expo'} onClick={scrollToTop} />
    </div>
  );
}
