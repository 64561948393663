import logo from "./logo.svg";
import "./App.css";
import Header from "./components/Header";
import Categories from "./components/Categories";
import Items from "./components/Items";
import { ScrollToTop } from "./components/ScrollToTop";
import { Footer } from "./components/Footer";
import Popup from "./components/Popup";
import "@radix-ui/themes/styles.css";
import OfferPopup from "./components/OfferPopup";

function App() {
  return (
    <>
      <Header />
      <div className="menu-app">
        {/* <Popup /> */}
        <OfferPopup />
        <Categories />
        <Items />
        <ScrollToTop />
        <Footer />
      </div>
    </>
  );
}

export default App;
