import React, { useEffect, useState } from "react";
import Item from "./Item";
import client, { urlFor } from "../sanity";
import lines from "../utils/lines2.png";
import hot from "../utils/hot.png";

function Hots() {
  const [hots, setHots] = useState([]);
  useEffect(() => {
    client
      .fetch(
        `*[_type == "meal" && category->name == "Sıcak İçecekler"] | order(priority asc) {
      ...,
     category ->{
       ...
     }
    }`
      )
      .then((data) => {
        setHots(data);
      });
  }, []);
  return (
    <>
      <div
        className="flex flex-wrap m-auto w-full justify-center space-y-2"
        name="Sıcak İçecekler"
      >
        {/* <div
					className={`bg-[#A4C8C2] flex flex-col w-full self-center items-center content-center rounded-lg h-[200px]`}
				>
					<img
						src={hot}
						className="w-[50px] mt-12 h-[50px] justify-center items-center"
					/>
					<h1
						className={` text-white text-4xl mb-1 font-extralight m-auto pb-3`}
					>
						Sıcak İçecekler
					</h1>
					<img
						src={lines}
						className="w-full relative content-end bottom-0 h-[25px] select-none rounded-b-lg"
					/>
				</div> */}
        <div className="flex h-[120px] bg-[#A4C8C2] w-full rounded-lg justify-center items-end relative">
          <div className="flex flex-col justify-center items-center">
            <img
              src={hot}
              alt="Sıcak İçecekler"
              className="w-[40px] mt-12 h-[40px] justify-center items-center"
            />
            <h2 className="text-4xl mb-2 text-white font-light">
              Sıcak İçecekler
            </h2>
          </div>
        </div>
        <div className="w-full grid grid-cols-2 gap-2">
          {hots?.map((item) => {
            return (
              <Item
                key={item._id}
                name={item.name}
                price={item.price}
                image={item.image}
                description={item.description}
              />
            );
          })}
        </div>
      </div>
    </>
  );
}

export default Hots;
