import React, { useEffect, useState } from "react";
import client from "../sanity";
import CategoryCard from "./CategoryCard";
import { useHorizontalScroll } from "./useSideScroll";

function Categories() {
  const scrollRef = useHorizontalScroll();
  const [categories, setCategories] = useState([]);
  useEffect(() => {
    client
      .fetch(
        `*[_type == "category"] | order(priority asc){
      ...
    }`
      )
      .then((data) => {
        setCategories(data);
      });
  }, []);
  return (
    <div
      ref={scrollRef}
      style={{ overflow: "auto" }}
      className="flex flex-row sticky w-[99%] z-30 bg-red top-[60px] bg-white"
    >
      {categories.map((category) => (
        <CategoryCard key={category._id} img={category.image} title={category.name} />
      ))}
    </div>
  );
}

export default Categories;
