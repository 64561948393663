import React, { useEffect, useState } from "react";
import Item from "./Item";
import client from "../sanity";
import lines from "../utils/lines2.png";
import clock2 from "../utils/time2.png";
import Warning from "../components/Warning";
function Pancakes() {
  const [pancakes, setPancakes] = useState([]);
  useEffect(() => {
    client
      .fetch(
        `*[_type == "meal" && category->name == "Pancakes"] | order(priority asc){
      ...,
     category ->{
       ...
     }
    }`
      )
      .then((data) => {
        setPancakes(data);
      });
  }, []);
  return (
    <>
      <div
        className="flex flex-wrap m-auto w-full justify-center space-y-2 "
        name="Pancakes"
      >
        {/* <div
					className={`bg-[#FED9B3] flex flex-col w-full self-center items-center content-center rounded-lg h-[200px]`}
				>
					<div className="flex self-start content-start justify-start h-full p-2 m-[5px]">
						<img
							loading="lazy"
							className="w-[50px] h-[50px]"
							alt={"20dk"}
							src={clock2}
						></img>
						<p
							className={`text-left justify-start w-full self-start h-full text-[#713810] `}
						>
							{" "}
							HAZIRLAMA SÜRESİ
							<br />
							<span className="font-extralight">20 DAKİKADIR</span>
						</p>
					</div>
					<h1
						className={` text-[#713810] text-5xl font-extralight m-auto pb-3`}
					>
						Pancakes
					</h1>
					<img
						src={lines}
						className="w-full relative content-end bottom-0 h-[25px] select-none rounded-b-lg"
					/>
				</div> */}
        {/* <Warning /> */}
        <div className="flex h-[120px] bg-[#FED9B3] w-full rounded-lg justify-center items-end relative text-[#713810]">
          <div className="flex absolute top-0 p-2 left-0">
            <img
              loading="lazy"
              className="w-[50px] h-[50px]"
              alt={"20dk"}
              src={clock2}
            ></img>
            <p className={`text-left justify-start w-full self-start h-full  `}>
              {" "}
              HAZIRLAMA SÜRESİ
              <br />
              <span className="font-extralight">20 DAKİKADIR</span>
            </p>
          </div>
          <h2 className="text-4xl mb-2 font-light">Pancakes</h2>
        </div>
        <div className="w-full grid grid-cols-2 gap-2">
          {pancakes?.map((item) => {
            return (
              <Item
                key={item._id}
                name={item.name}
                price={item.price}
                image={item.image}
                description={item.description}
              />
            );
          })}
        </div>
      </div>
    </>
  );
}

export default Pancakes;
